<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="produtores"
      :headers="headers"
      :slots="['item.TelCel', 'item.Ativo']"
      :actions="
        $user.get().role === 'admin' || $user.get().role === 'consultoria'
          ? actions
          : []
      "
    >
      <template v-slot:[`item.TelCel`]="{ item }">
        {{ item.TelCel ? celular(item.TelCel) : ' - ' }}
      </template>

      <template v-slot:[`item.Ativo`]="{ item }">
        <v-btn
          v-if="item.Ativo === 'Ativo' || item.Ativo === 1"
          rounded
          small
          plain
          text
          class="teal lighten-1 white--text"
          @click="updateStatus(item)"
          >{{ item.Ativo }}</v-btn
        >
        <v-btn
          v-else
          text
          rounded
          small
          plain
          class="red lighten-1 white--text"
          @click="updateStatus(item)"
          >{{ item.Ativo }}</v-btn
        >
      </template>

      <template
        v-if="
          $user.get().role === 'admin' || $user.get().role === 'consultoria'
        "
        v-slot:btnCadastro
      >
        <v-row>
          <v-col>
            <v-btn
              color="green darken-1"
              dark
              class="rounded-lg"
              :loading="loadingSheet"
              @click="exportSheet"
            >
              <v-icon class="mr-2">mdi-file-excel</v-icon>

              <span style="font-size: 16px; font-weight: 600">Exportar</span>
            </v-btn>
          </v-col>
          <v-col>
            <router-link :to="`/${$user.get().role}/produtores/form`">
              <v-btn color="success"> Novo Produtor </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
    </crud-list>
  </v-card>
</template>

<script>
import { celular } from '../../utils/masks'
import { JsonToExcel } from '../../utils/Worksheet'

export default {
  data() {
    return {
      valid: true,
      rulesNumVisita: [
        v =>
          (v && v > 0 && v <= 99999) || 'Este valor deve estar entre 0 e 99999',
      ],
      actions: [
        {
          title: 'Editar Produtor',
          color: 'yellow darken-3',
          click: item =>
            this.$router.push({
              path: `/${this.$user.get().role}/produtores/form`,
              query: {
                id: item.id,
              },
            }),

          icon: 'mdi-pencil',
        },
      ],
      produtor: null,
      produtores: [],
      loading: true,
      loadingSheet: false,
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Status',
          sortable: true,
          width: 'auto',
          value: 'Ativo',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: true,
          value: 'name',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'e-mail',
          value: 'email',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'Inscrição rural',
          value: 'InscricaoRural',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Matrícula',
          value: 'Matricula',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'Telefone celular',
          value: 'TelCel',
          width: 'auto',
        },
      ],
    }
  },
  created() {
    this.getProdutores()
  },
  methods: {
    celular,
    getProdutores() {
      this.api.get.produtores().then(data => {
        data = data.map(item => {
          item.Ativo = this.typeState(item.Ativo)
          return item
        })
        this.produtores = data
        this.produtor = this.produtores[0]
        this.loading = false
      })
    },
    exportSheet() {
      this.loadingSheet = true

      new JsonToExcel(
        this.produtores,
        this.headers,
        'produtores.xlsx',
        'Produtores',
      )

      this.loadingSheet = false
    },
    typeState(status) {
      if (status === '1') {
        return 'Ativo'
      } else {
        return 'Inativo'
      }
    },

    updateStatus(item) {
      this.Swal.fire({
        title: `${item.Ativo === 'Ativo' ? 'Inativar' : 'Ativar'} Produtor`,
        text: `Tem certeza que deseja ${
          item.Ativo === 'Ativo' ? 'inativar' : 'ativar'
        } esse produtor?`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#ff5252',
        confirmButtonText: `Sim, desejo ${
          item.Ativo === 'Ativo' ? 'inativar' : 'ativar'
        }!`,
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
          this.api.update
            .produtorState(item.id, {
              state: item.Ativo === 'Ativo' ? 0 : 1,
            })
            .then(res => {
              this.produtores = this.produtores.map(produtor => {
                if (produtor.id === item.id) {
                  produtor.Ativo = item.Ativo === 'Ativo' ? 'Inativo' : 'Ativo'
                }
                return produtor
              })

              this.Swal.fire({
                title: 'Sucesso',
                text: `Produtor ${
                  res.ATIVO ? 'ativado' : 'inativo'
                } com sucesso`,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              })
            })
          return true
        },
      })
    },
  },
}
</script>
